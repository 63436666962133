import "../startApplication/StartApplication.scss";
import streaming from "../../../assets/images/startApplication/streaming.svg";
import cvPattern from "../../../assets/images/startApplication/CoursevitaPat.svg";
import ellipse from "../../../assets/images/startApplication/EllipseBg.png";
import chat from "../../../assets/images/startApplication/chat.png";
import clipboard from "../../../assets/images/startApplication/clipboard.svg";
import game from "../../../assets/images/startApplication/game.svg";
import time from "../../../assets/images/startApplication/Time.svg";
import Yes from "../../../assets/images/startApplication/Yes.svg";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const StartApplication = ({ courseContent }) => {
  const navigate = useNavigate();
  const rightData = [
    {
      icon: streaming,
      label: "100% Live sessions",
    },
    {
      icon: game,
      label: "Engaging & Immersive Learning Experiences",
    },
    {
      icon: chat,
      label: "Community to up to date with trends ",
    },
    {
      icon: clipboard,
      label: "Industry Simulation Projects & Case Studies",
    },
  ];
  const leftData = [
    {
      icon: Yes,
      label: "5+ guided projects under a mentorship.",
    },

    {
      icon: Yes,
      label: "Get a customized career roadmap.",
    },
    {
      icon: Yes,
      label: "Get a free personal counseling session.",
    },
  ];
  const handleApplyNow = () => {
    navigate(`/checkout/${courseContent?.learnmoreCourse}`);
  };

  return (
    <Box className="applicationSection">
      <Box
        sx={{
          m: { md: "0px 32px", xs: "0px 16px" },
          flexDirection: { md: "row", xs: "column" },
        }}
        className="contentWrapper"
      >
        <Box
          className="leftSection"
          sx={{
            flexBasis: { sm: "50%" },
            p: { sm: "100px 10px 50px 90px", xs: "40px 16px 0 16px" },
          }}
        >
          <Typography
            className="titlem"
            sx={{
          
              mb: {xs:"35px",md:"35px"} ,
              textAlign: { xs: "center", sm: "left" },
              fontSize: { xs: "20px !important", md: "40px !important" },
            }}
          >
            Start your journey <span style={{ color: "#FF7262" }}>today!</span>
          </Typography>
          {rightData?.map((item, index) => {
            return (
              <Box sx={{ mb: { xs: "26px", md: "38px" }, display: "flex" }}>
                <Box
                  component="img"
                  src={item.icon}
                  sx={{ width: "30px", height: "30px", mr: "16px" }}
                />
                <Typography
                  className="label"
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >
                  {item.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          className="rightSection"
          sx={{ flexBasis: { sm: "50%" }, mt: { xs: "20px", md: "60px" }, mb:{ xs: "0px", md: "30px" } }}
        >
          <Box
            className="cardWrapper"
            sx={{
              width: { xs: "100%", md: "80%" },
              mx: "16px",
              maxWidth: "570px",
            }}
          >
            <Box
              className="imgWrapper"
              sx={{
                p: "43px 34px 0px 34px",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${courseContent.imageSource}) lightgray 50% / cover no-repeat`,
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md: "row" }
                }}
              >
                <Typography
                  className="title"
                  sx={{ fontSize: { xs: "20px", md: "24px" } }}
                >
                  {courseContent?.course}
                </Typography>

                <Box
  className="courseDuration"
  sx={{
    display: "flex",
    alignItems: "center",
    gap: { xs: "8px", md: "8px" }, // Adjust spacing for mobile
    flexDirection: { xs: "row", md: "row" }, // Keep items inline
  }}
>
  <Box
    component="img"
    sx={{
      height: { xs: "12px", md: "16px" }, // Adjust icon size
      alignSelf: "center", // Ensure the icon is vertically centered
    }}
    src={time}
  />
  <Typography
    className="label2"
    sx={{
      fontSize: { xs: "12px", md: "14px" }, // Adjust font size for mobile and larger screens
      lineHeight: "1", // Ensure the line height doesn't interfere with alignment
      marginTop: { xs: "-1px", md: "0px" }, // Move text up in mobile view
    }}
  >
    {courseContent?.period}
  </Typography>
</Box>

              </Box>
              <Box sx={{ py: { xs: "11px", md: "23px" } }}>
                {leftData?.map((item, index) => {
                  return (
                    <Box sx={{ mb: "12px", display: "flex" }}>
                      <Box
                        component="img"
                        src={item.icon}
                        sx={{ width: "30px", height: "30px", pr: "13px" }}
                      />
                      <Typography className="textlabel">
                        {item?.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                p: { md: "0px 34px 0px 34px", xs: "0 20px 0 20px" },
              }}
            >
              <Typography className="cost">
                <span>
                  <del>₹{courseContent?.costPrice}</del>
                </span>{" "}
                ₹{courseContent?.sellingPrice}/-
              </Typography>
              <Button
                className="startBtn"
                onClick={handleApplyNow}
              >
                Start Application
              </Button>
              {/* <Typography className="deadline">
                Application Deadline:17thAPRIL, 2024
              </Typography> */}
            </Box>
          </Box>
        </Box>
        <Box
          component="img"
          src={cvPattern}
          sx={{
            position: "absolute",
            top: "21px",
            left: "21px",
            zIndex: "0",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
        <Box
          component="img"
          src={ellipse}
          sx={{
            position: "absolute",
            top: "21px",
            left: "21px",
            zIndex: "-1",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
      </Box>
    </Box>
  );
};

export default StartApplication;
