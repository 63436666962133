"use client";
import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zIndex from "@mui/material/styles/zIndex";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  annotationPlugin
);

const LineGraph = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const xAxisLabels = [
      "Career starts",
      "1 year",
      "2 years",
      "Joining Coursevita",
      "After Coursevita",
    ];
    const dataPoints = [3.2, 4.8, 5.3, 5.9, 11];

    const config = {
      type: "line",
      data: {
        labels: xAxisLabels,
        datasets: [
          {
            // Purple segments until Joining Coursevita
            label: "Initial Progress",
            data: [3.2, 4.8, 5.3, 5.9, null],
            borderColor: "#7234F7",
            backgroundColor: "#7234F7",
            borderWidth: 2,
            tension: 0.4,
            pointBackgroundColor: "#7234F7",
            pointBorderColor: "#7234F7",
            pointBorderWidth: 2,
            pointRadius: 4,
            z: 10,
          },
          {
            // Only the final segment in red
            label: "Progress After Coursevita",
            data: [null, null, null, 5.9, 11],
            borderColor: "green",
            backgroundColor: "green",
            borderWidth: 2,
            tension: 0.4,
            pointBackgroundColor: "green",
            pointBorderColor: "green",
            pointBorderWidth: 2,
            pointRadius: 4,
            z: 10,
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 2000,
          easing: "easeOutQuart",
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "",
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Average Salary (LPA) in Rupees",
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          tooltip: {
            enabled: true,
            intersect: false,
            callbacks: {
              label: function (context) {
                return `${context.raw} LPA`;
              },
            },
          },
          annotation: {
            annotations: {
              label1: {
                type: "label",
                xMin: 3,
                xMax: 4,
                yMin: 5,
                yMax: 5,
                backgroundColor: "white",
                content: "3 Months",
                color: "black",
                font: {
                  size: 14,
                  weight: "semi-bold",
                },
                borderColor: "white",
                borderRadius: 4,
                borderWidth: 2,
                padding: 6,
              },
            },
          },
        },
      },
    };

    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        p: { xs: 2, md: 3 },
        height: 600, 
        width: "100%",
      }}
    >
        <Typography 
                variant="h4" 
                sx={{ 
                  textAlign: 'center', 
                  mb: 4,
                  fontSize: { xs: '1.5rem', md: '2rem' },
                  fontWeight: 600,
                  '& span': {
                    color: '#FF7262'
                  }
                }}
              >
                Unlock your<span> Career Growth </span>
              </Typography>
      <Box
        p={{ md: 4, xs: 0 }}
        sx={{
          backgroundColor: "white",
          borderRadius: "16px",
          width: "100%",
          height: "80%",
          maxWidth: "1500px",
        }}
      >
        <canvas ref={chartRef} style={{ width: "100%", height: "100%" }}></canvas>
      </Box>
    </Box>
  );
};

export default LineGraph;