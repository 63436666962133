import "../certificateAwaiting/CertificateAwaiting.scss";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import certificateIcon from "../../../assets/images/certAwaiting/certificateSvg.png";
import increase from "../../../assets/images/certAwaiting/increase.png";
import share from "../../../assets/images/certAwaiting/share.png";
// import CertificateColour from "../../../assets/images/certAwaiting/CertificateColour.png";
// import Certificateblack from "../../../assets/images/certAwaiting/Certificateblack.png";


const CertificateAwaiting = ({ courseContent }) => {
  // Default to black certificate image from course data or fallback
  const [imgSrc, setImgSrc] = useState(
    courseContent?.certificates?.blackVersion || '/images/certificates/Certificateblack.png'
  );

  const data = [
    {
      image: certificateIcon,
      text1: "Industry-recognized certification",
      text2: "Our industry-recognized certifications are more than just badges—they're the keys to unlocking new opportunities"
    },
    {
      image: share,
      text1: "Share your certificate easily",
      text2: "Easily share your certificate and showcase your accomplishments to the world."
    },
    {
      image: increase,
      text1: "Boost productivity",
      text2: "Chance to increase your productivity"
    }
  ];

  const handleMouseEnter = () => {
    setImgSrc(courseContent?.certificates?.colorVersion || '/images/certificates/CertificateColour.png');
  };

  const handleMouseLeave = () => {
    setImgSrc(courseContent?.certificates?.blackVersion || '/images/certificates/Certificateblack.png');
  };

  return (
    <Box
      className="certificateAwaiting"
      sx={{
        p: { xs: "66px 16px 40px 16px", md: " 66px 58px 40px 58px" },
        flexDirection: { md: "row", xs: "column" },
        }}
    >
      <Box
        className="sectionLeft"
        sx={{ flexBasis: { sm: "50%" }, p: { xs: "16px" } }}
      >

        <Typography className="title1"sx={{fontSize:{xs:"20px !important",md:"40px !important"}}} >Benefits of getting certified</Typography>
        <Typography className="subtitle"sx={{fontSize:{xs:"20px !important",md:"40px !important"}}}> in {courseContent?.course}</Typography>
        
        <Box className="stepContainer">
          {data?.map((item, index) => {
            return (
              <Box
                display={"flex"}
                key={index}
                sx={{ pb: { xs: "26px", md: "40px" } }}
              >
                <Box
                  component="img"
                  src={item.image}
                  sx={{
                    height: { xs: "48px", md: "54px" },
                    width: { xs: "48px", md: "54px" },
                    mr: "16px",
                  }}
                  alt="icon"
                />
                <Box className="stepContent">
                  <Typography className="text1">{item.text1}</Typography>
                  <Typography className="text2"> {item.text2}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      
      <Box
        className="sectionRight"
        sx={{
          flexBasis: { sm: "50%" },
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
          mt: { xs: "50px" },
          position: "relative",
        }}
      >
         <Box
      component="img"
      src={imgSrc}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
      sx={{
        height: { xs: '250px', sm: '310px',md:"380px" },
        width: { xs: '100%', md: '90%' },
      }}
    />
      </Box>
    </Box>
  );
};

export default CertificateAwaiting;




 