import { Box } from "@mui/material";
import Footer from "../commonComponents/footer/Footer";
import CertificateAwaiting from "./certificateAwaiting/CertificateAwaiting";
import CorePoints from "./corePoints/CorePoints";
import CourseOutcomes from "./courseOutcomes/CourseOutcomes";
import CoursePreview from "./coursePreview/CoursePreview";
// import CourseStats from "./courseStats/CourseStats";
import Journey from "./journey/Journey";
import Mentors from "./mentors/Mentors";
import { Helmet } from 'react-helmet';
import Questions from "./questions/Questions";
import StartApplication from "./startApplication/StartApplication";
import Tools from "./tools/Tools";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Skills from "./skills/Skills";
import Roles from "./roles/Roles";
import Navbar from "../commonComponents/navbar/Navbar";
import CoursevitaComparison from "./whyCoursevitaNewTable/CoursevitaComparison";
import LineGraph from "./whyCoursevitaNewTable/graph";
import LearningPathSection from "./LearningPathSection/LearningPathSection";

const CoursePreviewPage = ({ coursesContent }) => {
  const { pathname } = useLocation();
  const { courseName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const courseContent = coursesContent.find(course => course.learnmoreCourse === courseName);
  return (

    <Box sx={{ backgroundColor: "#F3F4FD", overflow: "hidden" }}>
      <Helmet>
        <title>{courseContent ? `Courses - ${courseContent.course}` : 'Course Page'}</title>
        <meta name="description" content={courseContent ? courseContent.courseDesc : 'Course description'} />
        <meta name="keywords" content={courseContent ? courseContent.keywords.join(', ') : 'courses, learning'} />
      </Helmet>
      <Navbar />
      <CoursePreview courseContent={courseContent} />
      <CorePoints courseContent={courseContent} />
      <LearningPathSection/>
      <Tools courseContent={courseContent} />
      <Skills courseContent={courseContent} />
      <Roles courseContent={courseContent} />
      <Mentors courseContent={courseContent}/>
      <CourseOutcomes/>
      <Journey courseContent={courseContent} />
      <CoursevitaComparison courseContent={courseContent} />
      <LineGraph />
      <CertificateAwaiting courseContent={courseContent} />
      <StartApplication courseContent={courseContent} />
      <Questions courseContent={courseContent} />
      <Footer />
    </Box>
  );
};

export default CoursePreviewPage;
