import { Typography, Box, IconButton } from "@mui/material";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../journey/Journey.scss";

const Journey = ({courseContent}) => {
  const data= courseContent?.modules;
 
  const scrollContainerRef = useRef(null);
  const [lineColor, setLineColor] = useState(
    "radial-gradient(108.33% 108.33% at 50% 100%, rgba(114, 52, 247, 0.50) 0%, rgba(184, 84, 174, 0.50) 61.47%, rgba(254, 116, 101, 0.50) 100%)"
  );
  const [expandedModule, setExpandedModule] = useState(null);

  const handleExpand = (index) => {
    setExpandedModule(expandedModule === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const scrollTop = scrollContainer.scrollTop;

        if (scrollTop > 200) {
          setLineColor("#FF7262");
        } else {
          setLineColor("#CBCBCB");
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Box className="journeySnapshot" sx={{ p: "70px 16px 10px 16px" }}>
      <Typography className="title" sx={{fontSize:{xs:"20px !important",md:"40px !important"}}}>
        A Quick snapshot of <span style={{ color: "#FF7262" }}>your journey</span>
      </Typography>
      <Box
        ref={scrollContainerRef}
        className="journey-scroll-container"
        style={{ position: "relative", minHeight: "400px", overflowY: "auto" }}
      >
        <VerticalTimeline lineColor={lineColor} lineWidth="2px !important">
          {data.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "#200e43",
                color: "#FFFFFF",
                padding:{md: "25px 30px",xs:"16px"},
                borderRadius: "16px",
                border: "2px solid #55487126",
                boxShadow: "none",
                cursor: "auto",
              }}
              iconStyle={{
                background:
                  "radial-gradient(108.33% 108.33% at 50% 100%, rgba(114, 52, 247, 0.50) 0%, rgba(184, 84, 174, 0.50) 61.47%, rgba(254, 116, 101, 0.50) 100%)",
                alignSelf: "center",
              }}
            >
              <Typography className="label">Module - {index + 1}</Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => handleExpand(index)} >
                <Typography className="description">{item.moduleHeading}</Typography>
                <IconButton onClick={() => handleExpand(index)} style={{ color: "#FFFFFF" }}>
                  {expandedModule === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              {expandedModule === index && (
                <Box mt={2}>
                  {item.description.map((desc, descIndex) => (
                    <Typography key={descIndex} className="description">
                      {desc}
                    </Typography>
                  ))}
                </Box>
              )}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Box>
    </Box>
  );
};

export default Journey;

