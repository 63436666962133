import { Box, Typography } from "@mui/material";
import horizontalLogo from "../../../assets/images/footer/horizontalLogo.svg";
import facebook from "../../../assets/images/footer/facebook.png";
import instagram from "../../../assets/images/footer/instagram.svg";
import Linkedin from "../../../assets/images/footer/Linkedin.svg";
import whatsApp from "../../../assets/images/footer/whatsappicon.svg";
import Location from "../../../assets/images/footer/Location.svg";
import call from "../../../assets/images/footer/Call.svg";
import email from "../../../assets/images/footer/email.svg";

import { useNavigate } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const navigate = useNavigate();

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
    window.scrollTo(0, 0);

  };

  const buttonClick = () => {
    sessionStorage.setItem("scrollToCourses", "true");
    navigate("/", { state: { scrollToCourses: true } });
  };
  return (
    <>
      <Box
        className="footerWrapper"
        sx={{
          p: { xs: "16px", md: " 50px 32px 32px 32px" },
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          className="footerWrapperSections"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box className="footerSection" sx={{ pb: "30px" }}>
            <Box
             
              sx={{ width: { xs: "124px", md: "202px" },  "&:hover": {
                cursor: "pointer",
              },}}
              component="img"
              src={horizontalLogo}
              alt="horizontalLogo"
              onClick={(e) => {
                onClickHandler(e, "/");
              }}
            />
            <Typography
              className="footerText"
              sx={{
                pt: { xs: "13px", md: "23px" },
                fontSize: { xs: "13px", md: "20px" },
              }}
            >
              Building Careers For Tomorrow.
            </Typography>
            <Box>
              <ul className="socialMedia">
                <li className="socialAcc">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=61556411741027"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                </li>
                <li className="socialAcc">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/coursevita_official/"
                  >
                    <img src={instagram} alt="instagram" />
                  </a>
                </li>
                <li className="socialAcc">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/company/coursevita/"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </li>
                <li className="socialAcc">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://wa.me/+917050004259"
                  >
                    <img src={whatsApp} alt="whatsApp" />
                  </a>
                </li>
              </ul>
            </Box>
            <Box className="contactDetails">
              <Box
                className="footerDetails contact"
                onClick={() => {
                  window.location.href = "mailto:reachus@coursevita.com";
                }}
                sx={{ cursor: "pointer" ,pt:{md:"30px",xs:"20px"}}}
              >
                <img src={email} alt="email" />
                reachus@coursevita.com
              </Box>
              <Typography sx={{pt:{xs:"8px",md:"12px"}}} className="footerDetails contact" onClick={()=>{
                window.location.href = "tel:8019666972";
              }}>
                <img src={call} alt="call" />
                +91- 8019666972
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pb: "30px" }}>
            <Typography className="footerText footerHeaderText">
              Company
            </Typography>
            <Typography className="footerText companyText">
              <span>
                <a
                  className="innerText"
                  href="https://coursevita.zohorecruit.in/jobs/Careers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </span>
              <span>
                <a
                  className="innerText"
                  href="/findyourcareer"
                  onClick={(e) => onClickHandler(e, "/findyourcareer")}
                >
                  Find your career
                </a>
              </span>
              <span>
                <a
                  className="innerText"
                  href="#"
                  onClick={(e) => window.open('https://upskill.coursevita.com/aboutus', '_blank')}
                >
                  About Us
                </a>
              </span>
              {/* <span>
                <a className="innerText" href="/contactUs" onClick={(e) => onClickHandler(e, "/contactUs")}>
                  Contact us
                </a>
              </span> */}
              <span>
                <a
                  className="innerText"
                  href="/terms"
                  onClick={(e) => onClickHandler(e, "/terms")}
                >
                  Terms and conditions
                </a>
              </span>{" "}
              <span>
                <a
                  className="innerText"
                  href="/privacy-policy"
                  onClick={(e) => onClickHandler(e, "/privacy-policy")}
                >
                  Privacy Policy
                </a>
              </span>
              <span>
                <a
                  className="innerText"
                  onClick={(e) => window.open("https://coursevita-main-site.s3.eu-north-1.amazonaws.com/CourseVita_Refund_Deferral_Policy.docx.pdf", '_blank').focus()}
                >
                  Refund Policy
                </a>
              </span>
            </Typography>
          </Box>
          <Box sx={{ pb: "30px" }}>
            <Typography className="footerText footerHeaderText">
              Upskill
            </Typography>
            <Typography className="footerText companyText">
              <span>
                <Box
                  className="innerText"
                  sx={{ p: "0px !important" }}
                  onClick={(e) => buttonClick("courses")}
                >
                  Courses
                </Box>
              </span>
              <span>
                <a
                  className="innerText"
                  href="/blogs"
                  onClick={(e) => onClickHandler(e, "/blogs")}
                >
                  Blogs
                </a>
              </span>
              {/* <span>
                <a className="innerText" href="/events" onClick={(e) => onClickHandler(e, "/events")}>
                  Events
                </a>
              </span> */}
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "460px", pl: { md: "20px" } }}>
            <Typography className="footerText footerHeaderText">
              Address
            </Typography>
            <Typography className="footerText addressText">
              <Box
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginBottom: "20px",
                  lineHeight: "20px",
                }}
              >
                <img src={Location} alt="location" />

                <span style={{ fontSize: "14px" }}>
                Plot 118, 3rd floor, Spaces and more, Business park, Lumbini Avenue,
                  Gachibowli, Hyderabad, Telangana 500032
                </span>
              </Box>
              <Box className="mapContainer">
                <iframe
                  title="Google Maps"
                  width="100%"
                  height="200"
                  loading="lazy"
                  allowFullScreen
                  style={{ border: "none", borderRadius: "13px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.442446391627!2d78.37152171489897!3d17.432664888068615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93000c5eb6bb%3A0x8d3c842f97b1fb7c!2sCourseVita!5e0!3m2!1sen!2sin!4v1632052839338!5m2!1sen!2sin"
                ></iframe>
              </Box>
            </Typography>
          </Box>
        </Box>
        <hr />

        <Box
          className="footerDetails"
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Box>
            <Typography
              className="footerText copyright"
              sx={{ pt: { xs: "40px", md: "0" } }}
            >
              @ Copyright &copy;2024 CourseVita (BHUVANA INFORMATION TECHNOLOGIES PVT. LTD.). All Rights Reserved.
            </Typography>
          </Box>
          <Box>
           
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
