import { Box, Typography } from "@mui/material";
import "./RefundPolicy.scss";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const RefundPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box className="refundPolicy">
      <Box className="refundPolicyContent">
        <Typography className="boldTitle">​ REFUND POLICY:</Typography>
        <br />
        <Typography className="desc">
          CourseVita appreciates and values your purchase on our website
          (https://www.coursevita.com/). We take our refund policy seriously and
          make sure go through it carefully. All defined terms used below shall
          have the meanings set forth in our Terms and Conditions. <br /> <br />
          BHUVANA INFORMATION TECHNOLOGIES PRIVATE LIMITED (hereby referred as
          ‘CourseVita'), reserves the sole right to repeal or reschedule a
          training or change the trainer, location of training because of
          insufficient enrollments, trainer / instructor unavailability or force
          majeure conditions. <br />
        </Typography>{" "}
        <br />
{/*         <Typography className="boldTitle">
        Student refund window
        </Typography>
        <br />
        <Typography className="desc">
        At CourseVita, we prfioritize student satisfaction. If a student is not satisfied with their course, they can request a full refund within 24 hours of enrollment. After this period, refunds will not be processed, ensuring a smooth and fair experience for all.
        </Typography>
        <br /> */}
        <Typography className="boldTitle">
          Refund policy in case training is cancelled by CourseVita
        </Typography>
        <br />
        <Typography className="desc">
          In case CourseVita cancels/postpones an event, 100% of course fees
          will be refunded to the delegate if the refund raise request is within
          10 days of purchase of the course
        </Typography>
        <br />
        <Typography className="boldTitle">
          If the delegate opts for cancellation/refund:
        </Typography>
        <br />
        <Typography className="desc">
          Delegates should raise the request for refund within 24 Hours
          after attending the first session (classroom/Instructor led online). A
          formal request for a refund should be made through an official email
          to reachus@coursevita.com from the date of the course commencement.
          Any refund request after the above time frame will not be entertained.
          No refunds under any circumstances will be entertained if the
          delegate/participants attends 8 hours of the session If the
          delegate/participant doesn’t attend even a single live session the
          refund request should be raised within 30 days from the day of the
          payment. Any request after the above time frame will not be
          entertained
          <br />
        </Typography>
        <br />
        <Typography className="boldTitle">Refund Timeline</Typography>
        <br />
        <Typography className="desc">
          Approved refunds will be processed within 5-7 business days from the
          approval of the refund request. The credited amount will be
          transferred to the original payment method used by the
          delegate/participant.
          <br />
        </Typography>
        <Typography className="bold">
          <br />
          <br />
          If you have any other queries regarding our refund and rescheduling
          policy, drop us a mail at reachus@coursevita.com
        </Typography>
      </Box>
    </Box>
  );
};

export default RefundPolicy;
