import React from 'react';
import { Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CoursevitaComparison = ({ courseContent }) => {
  const comparisonData = courseContent?.comparisonData || [];
  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: { xs: '100%', sm: '600px', md: '1500px' }, 
      mx: 'auto', 
      p: { xs: 2, md: 3 }
    }}>
      <Typography 
        variant="h4" 
        sx={{ 
          textAlign: 'center', 
          mb: 4,
          fontSize: { xs: '1.5rem', md: '2rem' },
          fontWeight: 600,
          '& span': {
            color: '#FF7262'
          }
        }}
      >
        Why <span>Coursevita?</span>
      </Typography>

      <Box sx={{ 
        bgcolor: '#1D1037',
        borderRadius: { xs: '16px', md: '16px' },
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr'
      }}>
        {/* Header Row */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          borderBottom: '0.2px solid #BD89FF',
          fontSize: { xs: '0.875rem', md: '1rem' },
          textAlign:"center",
          position: 'relative',
          '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            width: '0.5px',
            height: '100%',
            bgcolor: '#BD89FF',
          },
          '&::before': {
            left: '33.33%',
          },
          '&::after': {
            left: '66.66%',
          }
        }}>
          <Box sx={{ 
            p: { xs: 1.5, md: 2 }, 
            color: 'white', 
            fontWeight: 600,
            fontSize: { xs: '0.75rem', md: '1rem' }
          }}>
            Aspect
          </Box>
          <Box sx={{ 
            p: { xs: 1.5, md: 2 }, 
            color: 'white', 
            fontWeight: 600,
            fontSize: { xs: '0.75rem', md: '1rem' }
          }}>
            Before
          </Box>
          <Box sx={{ 
            p: { xs: 1.5, md: 2 }, 
            background: 'linear-gradient(90deg, #7234F7 0%, #BD89FF 100%)',
            color: 'white',
            fontWeight: 600,
            fontSize: { xs: '0.75rem', md: '1rem' }
          }}>
            After
          </Box>
        </Box>

        {/* Data Rows */}
        {comparisonData.map((item, index) => (
          <Box
          key={index}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyContent: 'center',
            textAlign:{ xs: 'center', md: 'left' },
            alignItems: 'center',
            position: 'relative',
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              width: '0.5px',
              height: '100%',
              bgcolor: '#BD89FF',
            },
            '&::before': {
              left: '33.33%',
            },
            '&::after': {
              left: '66.66%',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '0.5px',
              background: index !== comparisonData.length
  ? 'linear-gradient(to right, #FFFFFF 0%, #BD89FF 50%, #FFFFFF 100%)'
  : 'none',
            },
          }}
        >
            <Box sx={{ 
              p: { xs: 0.3, md: 2 }, 
              color: 'white',
              fontSize: { xs: '0.7rem', md: '1rem' },
              textAlign: 'center',
              justifyContent:'center',
              alignItems:'center'
            }}>
              {item.aspect}
            </Box>
            <Box sx={{ 
              p: { xs: 1.5, md: 2 }, 
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 1, 
              fontSize: { xs: '0.75rem', md: '1rem' }
            }}>
              <svg width="0" height="0">
  <defs>
    <linearGradient id="gradient-fill" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#FFC1BA" />
      <stop offset="100%" stopColor="#FF301A" />
    </linearGradient>
  </defs>
</svg>

<CancelIcon
  sx={{
    fontSize: { xs: 16, md: 20 },
    mt: { xs: 0, md: '2px' },
  }}
  style={{ fill: "url(#gradient-fill)" }}
/>
              <span>{item.before}</span>
            </Box>
            <Box sx={{ 
              p: { xs: 1.5, md: 2 },
              bgcolor: 'white',
              color: '#000',
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 1, 
              fontSize: { xs: '0.75rem', md: '1rem' }
            }}>




              <svg width="0" height="0">
  <defs>
    <linearGradient id="gradient-fill-check-circle" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0.77%" stopColor="#7234F7" />
      <stop offset="100%" stopColor="#BD89FF" />
    </linearGradient>
  </defs>
</svg>

<CheckCircleIcon
  sx={{
    fontSize: { xs: 16, md: 20 },
    mt: { xs: 0, md: '2px' },
  }}
  style={{ fill: "url(#gradient-fill-check-circle)" }}
/>



              <span>{item.after}</span>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CoursevitaComparison;